import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Ism Maqsoor`}</strong>{` Or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`الاسم المقصور`}</strong>{` are those nouns which has ى (Alif Maqsoor)  at the end and before it is a Fatha.`}</p>
    <blockquote>
      <p parentName="blockquote">{`كل اسم آخره ألف قبلها فتحة`}</p>
    </blockquote>
    <br />
    <p><strong parentName="p">{`Examples`}</strong></p>
    <ul>
      <li parentName="ul">{`جاء الفتَ`}<strong parentName="li">{`ى`}</strong></li>
      <li parentName="ul">{`رأيت الفتَ`}<strong parentName="li">{`ى`}</strong>{` `}</li>
      <li parentName="ul">{`مررت بالفتَ`}<strong parentName="li">{`ى`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.75460122699387%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/klEQVQ4y41UW08TURDu3/dJGw3RaBBNIFoB9YUE4QEKRHtDDAKt9Ep3K2y7vey23ds553P2rNsu2xJ2k9nJnG/mOzNnJpNC5BNCzHRSicb5XwqxLwqS9YDwB/xjhCE4thhqtzZudI52X0AdQupWj0mtkN3pu49nGALdkYtLlSF3pSF71sbWtwL2c1UUKjqOyN7eK+FKmYLzRIQCg4mQBE/Sq1jL7OL5mwy2d3NY/3KA95v7WP96iHJyQsCkki+aQ/zpTKGOIKV266FFT6AMgBsSRXeTNEVAzJrhO9Lzc4aJOYXRN+F5TNqMsQB8vCnBz9eho+d6cJ25cM7vjVaikkPNKKOARMCxXHnm67jfPUJ5E90aFVl6SEI494ktR55zKjckRzyW7IXBxv+xtcY2wnuZb9tshvuZ236mS2JTbDCAU72GU6/BbdTBm3WMGy3YtgfhefBaTUwqFYzOL8Ea5KNpwSTc6bCu/bgq7FoVLok3HCJlVMrIvlzB3soL/P70Efm1VajZgyAT00T58xZ20k9xsZnByetX6JaKAab3UKaznfQznH3YQOndW5hEmjJVBerJMXqnRdwV82gSmUkZy9JtG518Dlohj/7PU7SPj2BSxrL7hoG/P75LzDj/BY387K62/A3nkxifqeULYbHLQsx3ybI1FQlcWHHRvUT2P0vbztt+CSzJAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ism Maqsoor",
          "title": "Ism Maqsoor",
          "src": "/static/0c64f165924e30321379192866521c03/7c2a6/maqsoor.png",
          "srcSet": ["/static/0c64f165924e30321379192866521c03/222b7/maqsoor.png 163w", "/static/0c64f165924e30321379192866521c03/ff46a/maqsoor.png 325w", "/static/0c64f165924e30321379192866521c03/7c2a6/maqsoor.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`ما إعراب الاسم المقصور`}</h2>
    <p>{`Maqsoor are nouns in Arabic grammar having special declension/case in Raf, Nasb and Jar form. `}</p>
    <br />
    <p>{`They have no Alamat of I'rab i.e. we don't see damma, fatha or kasra on Ism Maqsoor but they are معرب and not مبنی.  `}</p>
    <blockquote>
      <p parentName="blockquote">{`الاسم المقصور لا تظهر عليه علامات الإعراب`}</p>
    </blockquote>
    <h2>{`Reference`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.qutoofacademy.com/",
        "rel": "nofollow noopener"
      }}>{`Qutoof Academy`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      